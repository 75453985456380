<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      ads: {
        status: null,
        url: null,
      },

      show: true,

      total: {
        sponsored: "-",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      monthly: 'inactive',

      statData: null,

      binary: {
        modal: false,
        loading: false,
        side: "",

        points: {
          left: 0,
          right: 0,
        },
        percent: 0,
        bonus: "R$ -,--",
      },

      referral: {
        sponsored: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,
      }
    };
  },
  methods: {
    getAds() {
      api
        .get("ads/day")
        .then((response) => {
          if (response.data.status == "success") {
            this.ads.status = response.data.ads.status
            this.ads.url = response.data.ads.url
          }
        });
    },
    getMonthly() {
      api
        .get('report/monthly')
        .then(response => {
          this.monthly = response.data.status
          this.getStatData();
        })
    },
    getTotalSponsored() {
      api
        .get('network/sponsored/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.sponsored = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Plano",
          value: this.account.user.package
        },
        {
          title: "Total de Indicados",
          value: (this.show) ? this.total.sponsored.toString() : "**"
        },
        {
          title: "Total de Bonificações",
          value: (this.show) ? this.total.bonus : "R$ ***,**"
        },
        {
          title: "Saldo Disponível",
          value: (this.show) ? this.total.balance : "R$ ***,**"
        }
      ]
    },
    getBinaryResume() {
      api
        .get('network/binary')
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.points.left = this.$options.filters.points(response.data.resume.points.left);
            this.binary.points.right = this.$options.filters.points(response.data.resume.points.right);
            this.binary.percent = this.$options.filters.points(response.data.resume.percent);
            this.binary.bonus = this.$options.filters.currency(response.data.resume.bonus);
          }
        })
    },
    getBinarySide() {
      api
        .get('network/binary/side')
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.side = response.data.side
          }
        })
    },
    changeBinarySide() {
      this.binary.modal = false;
      this.binary.modal = true;
      this.binary.loading = false;
    },
    saveBinarySide() {
      this.binary.loading = true;

      api
        .post('network/binary/side', {
          side: this.binary.side
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.modal = false;
            this.binary.loading = false;
            this.$toast.success('O lado de derramamento foi alterado com sucesso!')
            this.getBinarySide();
          }
        })
        .finally(() => {
          this.binary.loading = false;
        })
    }
  },
  mounted() {
    this.getAds()
    this.getMonthly()
    this.getTotalSponsored()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getBinaryResume()
    this.getBinarySide()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="page-title-box d-flex justify-content-between align-items-center mb-3">
      <div>
        <h5>Bem-vindo à Lider Leads</h5>
        <p>Confira um resumo da sua conta.</p>
      </div>
      <div class="d-none pb-3">
        <button v-if="show" class="border-1 rounded p-1 bg-white" v-on:click="show = false; getStatData()">
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 12a3.5 3.5 0 1 1 7.001.001A3.5 3.5 0 0 1 8.5 12Zm2 0a1.501 1.501 0 0 0 3 0 1.501 1.501 0 0 0-3 0Z" fill="currentColor"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2 12s2.955-7 10-7 10 7 10 7-2.955 7-10 7-10-7-10-7Zm10 5c2.304 0 4.354-.933 6.093-2.771A12.146 12.146 0 0 0 19.75 12a12.145 12.145 0 0 0-1.657-2.229C16.353 7.932 14.303 7 12 7c-4.369 0-6.843 3.418-7.753 4.995.338.583.888 1.416 1.66 2.234C7.647 16.067 9.697 17 12 17Z"
              fill="currentColor"></path>
          </svg>
        </button>
        <button v-else class="border-1 rounded p-1 bg-white" v-on:click="show = true; getStatData()">
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="m3.78 19.5-1.414-1.42 2.338-2.35C2.844 13.86 2 11.852 2 11.852s2.955-7.034 10-7.034c1.13 0 2.156.182 3.08.486L18.367 2l1.414 1.422-16 16.078Zm.48-7.652c.338.586.888 1.425 1.663 2.25l.126.128.076.077 2.378-2.39v-.03l-.001-.03a3.51 3.51 0 0 1 3.5-3.518h.03l.03.001 1.373-1.38a8.04 8.04 0 0 0-1.433-.128c-4.364 0-6.836 3.436-7.742 5.02Z"
              fill="currentColor"></path>
            <path
              d="m18.667 7.384-1.422 1.43c.286.243.565.507.835.795.772.821 1.322 1.66 1.66 2.245a12.28 12.28 0 0 1-1.66 2.244c-1.737 1.846-3.782 2.78-6.08 2.78a7.82 7.82 0 0 1-2.41-.373l-1.55 1.56a9.71 9.71 0 0 0 3.96.825c7.045 0 10-7.035 10-7.035s-1.033-2.461-3.333-4.471Z"
              fill="currentColor"></path>
            <path d="M12.002 15.37a3.51 3.51 0 0 0 3.5-3.518c0-.387-.064-.76-.179-1.108L10.9 15.19c.347.116.718.18 1.103.18Z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </div>

    <div class="card">
      <div class="ads card-body py-2 d-flex align-items-center">
        <div>
          <h5 class="m-0 align-items-center">Indique um amigo</h5>
        </div>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="referral.sponsored"
            class="text-center bg-success rounded p-2 d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
              </path>
            </svg>
            <span class="ml-1 font-size-14 text-white">Compartilhar</span>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="w-100 text-center border-0 bg-default rounded p-2 d-flex align-items-center justify-content-center" v-clipboard:copy="referral.sponsored" v-on:click="$toast.success('O link foi copiado!')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
              <path
                d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
              </path>
            </svg>
            <span class="ml-1 font-size-14 text-white">Copiar</span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="ads && ads.status && ads.url" class="card">
      <div class="ads card-body py-2 d-flex align-items-center" :class="ads.status">
        <div>
          <h5 class="m-0 align-items-center">Atividade diária</h5>
        </div>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="this.ads.url" class="text-success bg-success rounded p-2 d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
              </path>
            </svg>
            <span class="ml-1 font-size-14 text-white">Compartilhar</span>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="w-100 text-center border-0 bg-default rounded p-2 d-flex align-items-center justify-content-center" v-clipboard:copy="this.ads.url" v-on:click="$toast.success('O link foi copiado!')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
              <path
                d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
              </path>
            </svg>
            <span class="ml-1 font-size-14 text-white">Copiar</span>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <fieldset class="binary pb-0">
      <legend>
        Binário
      </legend>
      <div class="binary-config">
        <button v-on:click="changeBinarySide()">
          <div class=" d-flex align-items-center">
            <div class="side">
              <template v-if="binary.side == 'l'">Esquerdo</template>
              <template v-if="binary.side == 'r'">Direito</template>
              <template v-else>...</template>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                <path
                  d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z">
                </path>
              </svg>
            </div>
          </div>
        </button>
      </div>
      <div class="row">
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-subdirectory-left font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.points.left }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">L. Esquerdo</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-subdirectory-right font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.points.right }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">L. Direito</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-check-double font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.percent }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">%</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-dollar font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.bonus }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Bônus</p>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <b-modal v-model="binary.modal" title="Lado de Derramento" modal-class="modal-right" hide-footer>
      <b-form @submit.prevent="saveBinarySide()">
        <b-form-group label="Escolha um lado" label-for="binary.side">
          <select v-model="binary.side" class="custom-select mb-2">
            <option value="l">Lado Esquerdo</option>
            <option value="r">Lado Direto</option>
          </select>
        </b-form-group>
        <b-button class="mb-0" :disabled="this.binary.loading" type="submit" variant="default" size="lg">
          Salvar
          <b-spinner v-if="binary.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.ads.pending {
  border: solid 2px #ff0000;
  border-radius: 3px;
  background: #ff000020;
}

.ads.approved {
  border: solid 2px #34C392;
  border-radius: 3px;
  background: #34C39220;
}

@media (max-width: 768px) {
  .ads {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .ads>div:nth-child(1) {
    width: 100%;
  }

  .ads>div:nth-child(2),
  .ads>div:nth-child(3) {
    width: 49%;
    margin-top: 1rem;
    margin-left: 0 !important;
  }
}

.binary {
  position: relative;
}

.binary-config {
  position: absolute;
  top: -27px;
  right: 15px;
  border: solid 1px #D1D1E3;
  border-radius: 6px;
}

.binary-config button {
  background: #fff;
  border: 0;
  border-radius: 50%;
}

.binary-config button .side {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>