<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Order: "Pedido",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      Order: "Pedido",
      "Payment Method": "Forma de Pago",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",

        items: null,
        status: null,
      },

      balance: {
        modal: false,
        loading: false,
        pay: '0.00',
        total: '0.00',
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("store/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;

              this.loading = false;
            } else {
              this.empty = true;
              this.message = response.data.message;
            }
          })
          .catch((error) => {
            this.errored = error;
          });
      }
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/store/orders">Pedidos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Pedido no. {{ order.id }}</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>#{{ order.id }}</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="errored">
          {{
          t(
          "We apologize, we are unable to retrieve information at this time. Please try again later."
          )
          }}
        </div>
        <div v-else-if="empty">{{ t("No records found.") }}</div>
        <div v-else class="p-2">
          <div class="d-flex">
            <h4 class="mb-0 font-size-15 flex-fill">
              {{ order.date }}
            </h4>
          </div>
          <hr />
          <div class="table-responsive">
            <div v-for="(td, index) in order.items" :key="index">
              <h3 class="font-size-16 mt-4 mb-0 font-weight-normal">
                {{ td.name }}
              </h3>
              <h3>{{ td.subtotal | currency }}</h3>
            </div>
          </div>
          <hr />
          <h3 class="font-size-16 mt-4 mb-2">{{ t("Status") }}</h3>
          <div v-for="(td, index) in order.status" :key="index">
            {{ td.date }} — {{ t(td.status) }}
          </div>
          <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
            <b-tab v-for="(pay, method) in order.payment.methods" :key="method">
              <template v-slot:title>{{ pay.name }}</template>
              <div v-if="method === 'pix'">
                <div class="text-center">
                  <img style="width: 250px" :src="'data:image/jpeg;base64,' + pay.base64" />
                  <p>Leia o qrcode acima ou</p>
                  <ol class="text-left">
                    <li>
                      Abra o app do seu banco ou seu app de pagamentos.
                    </li>
                    <li>Busque a opção de pagar com pix.</li>
                    <li>Copie e cole o seguinte código.</li>
                  </ol>

                  <div class="border rounded p-3 mb-3 text-left">
                    {{ pay.copypaste }}
                  </div>

                  <button class="btn btn-default" v-clipboard:copy="pay.copypaste" v-on:click="$toast.success('Copiado!')">
                    <i class="bx bx-copy font-size-18 align-middle"></i>
                    Copiar
                  </button>
                </div>
              </div>
              <div v-else-if="method === 'usdt'" class="text-center">
                <br>
                <img alt="" :src="pay.qrcode"><br>
                <p>
                  Envie exatamente o valor para realizar o pagamento<br>
                  <small v-if="pay.expiration"><br>Expiration in {{ pay.expiration }}</small>
                </p>
                <p class="font-size-16 mt-0 mb-0">
                  <small class="text-uppercase">Valor</small><br>
                  <strong>{{ pay.total }}</strong>
                  <button class="btn btn-link p-0 ml-2" v-clipboard:copy="pay.total" v-on:click="$toast.success('Copiado!')"><i class="bx bx-copy font-size-20 text-dark"></i></button>
                </p>
                <p class="font-size-16 mt-2 mb-0">
                  <small class="text-uppercase">Carteira</small><br>
                  <strong>{{ pay.address }}</strong>
                  <button class="btn btn-link p-0 ml-2" v-clipboard:copy="pay.address" v-on:click="$toast.success('Copiado!')"><i class="bx bx-copy font-size-20 text-dark"></i></button>
                </p>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>
