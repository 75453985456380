<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      "E-mail": "E-mail",
      "E-mail is required.": "E-mail é obrigatório.",
      "This value should be a valid e-mail.":
        "Este valor deve ser um e-mail válido.",
      Recover: "Recuperar",
      "Remember It?": "Lembrou-se?",
      "Sign In here": "Entrar",
      "The email is invalid.": "O email é inválido.",
      "A code for creating a new password has been sent to your email and you have up to 24 hours to change your password.":
        "Um código para criar uma nova senha foi enviado ao seu e-mail e você tem até 24 horas para alterar sua senha.",
    },
    es: {
      "E-mail": "E-mail",
      "E-mail is required.": "Correo electronico es requerido.",
      "This value should be a valid e-mail.":
        "Este valor debe ser un correo electrónico válido.",
      Recover: "Recuperar",
      "Remember It?": "¿Recuerdalo?",
      "Sign In here": "Identifícate aquí",
      "The email is invalid.": "El correo electrónico no es válido.",
      "A code for creating a new password has been sent to your email and you have up to 24 hours to change your password.":
        "Se ha enviado un código para crear una nueva contraseña a su correo electrónico y tiene hasta 24 horas para cambiar su contraseña.",
    },
  },
  components: {
    Layout,
    Languages,
  },
  data() {
    return {
      loading: false,
      success: false,
      username: "",
      email: "",
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  validations: {
    username: { required },
    email: { required, email },
  },
  methods: {
    recoverSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;

        if (this.email) {
          api
            .post("password/recover", {
              username: this.username,
              email: this.email,
            })
            .then((response) => {
              if (response.data.status == "success") {
                this.username = "";
                this.email = "";
                this.$v.$reset();

                this.alert.type = "alert-success";
                this.alert.message = response.data.message;

                this.success = true;
              } else {
                this.alert.type = "alert-danger";
                this.alert.message = response.data.message;
              }

              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img class="w-100" style="max-width: 300px" src="@/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="py-5">
          <div v-if="success" class="p-4">
            {{ t(this.alert.message) }}
          </div>
          <b-form v-else @submit.prevent="recoverSubmit">
            <div v-if="alert.message" :class="'alert ' + alert.type">
              {{ t(alert.message) }}
            </div>
            <b-form-group id="username" :label="t('Username')" label-for="username">
              <b-form-input id="username" v-model="username" type="text" :class="{ 'is-invalid': $v.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"></b-form-input>
              <div v-if="$v.username.$error" class="invalid-feedback">
                <span v-if="!$v.username.required">{{ t('Username is required.') }}</span>
              </div>
            </b-form-group>
            <b-form-group id="email" :label="t('E-mail')" label-for="email">
              <b-form-input id="email" v-model="email" type="email" :class="{ 'is-invalid': $v.email.$error }"></b-form-input>
              <div v-if="$v.email.$error" class="invalid-feedback">
                <span v-if="!$v.email.required">{{
                  t("E-mail is required.")
                  }}</span>
                <span v-if="!$v.email.email">{{
                  t("This value should be a valid e-mail.")
                  }}</span>
              </div>
            </b-form-group>
            <div class="mt-4 text-center">
              <b-button :disabled="loading == true" type="submit" variant="default" class="btn-lg btn-block">
                {{ t("Recover") }}
                <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </b-form>
          <div class="mt-4 text-center">
            <p class="text-dark">
              {{ t("Remember It?") }}
              <router-link tag="a" to="/login" class="font-weight-medium text-muted">{{ t("Sign In here") }}</router-link>
            </p>
            <Languages type="dropup"></Languages>
          </div>
        </div>
      </div>
      <div class="flex-fill login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>
