<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      validate: {
        loading: false,
        code: "",
      },

      change: {
        modal: false,
        loading: false,
        email: this.$store.state.account.user.email.address,
      },

      resend: {
        loading: false,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    validateCode() {
      if (this.validate.code) {
        this.validate.loading = true;

        api
          .post('user/email/validate', {
            code: this.validate.code,
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.$toast.success(response.data.message);
              this.$router.push("/");
            } else {
              this.validate.loading = false;
              this.$toast.error(response.data.message);
            }
          })
          .catch(error => {
            this.validate.loading = false;
            this.$toast.error(error.response.data.message);
          })
          .finally(() => {
            this.validate.loading = false;
          });
      }
    },
    resendCode() {
      this.resend.loading = true;

      api
        .get('user/email/resend')
        .then(response => {
          if (response.data.status == 'success') {
            this.resend.loading = false;
            this.$toast.success(response.data.message);
          } else {
            this.resend.loading = false;
            this.$toast.error(response.data.message);
          }
        })
        .catch(error => {
          this.resend.loading = false;
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.resend.loading = false;
        });
    },
    showChange() {
      this.change.modal = false;
      this.change.modal = true;
      this.change.loading = false;
      this.change.email = this.$store.state.account.user.email.address;
    },
    changeEmail () {
      this.change.loading = true;

      api
        .post('user/email/change', {
          email: this.change.email,
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.change.modal = false;
            this.change.loading = false;
            this.$toast.success(response.data.message);
          } else {
            this.change.loading = false;
            this.$toast.error(response.data.message);
          }
        })
        .catch(error => {
          this.change.loading = false;
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.change.loading = false;
        });
    },
  },
  mounted() {
    if (this.$store.state.account.user.email.status == 'validated') {
      this.$router.push("/");
    }
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 text-center">
          <img class="w-100" style="max-width: 300px" src="@/assets/images/logo.png" />
        </div>
        <div class="py-5">
          <p>Por favor, informe o código que enviamos em seu e-mail.</p>
          <b-form-group label-for="code">
            <b-form-input class="code" v-model="validate.code" maxlength="6"></b-form-input>
          </b-form-group>
          <div class="mb-4">
            <button class="btn btn-default btn-lg btn-block" v-on:click="validateCode()" :disabled="this.validate.loading || this.validate.code.length < 6">
              Validar
              <b-spinner v-if="validate.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </button>
          </div>
          <div>
            <button class="btn btn-outline-default btn-block" v-on:click="resendCode()">
              Reenviar E-mail
              <b-spinner v-if="resend.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </button>
            <button class="btn btn-outline-default btn-block" v-on:click="showChange();">Alterar E-mail</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="change.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h5>Alterar e-mail</h5>
        <b-form-group label-for="email">
          <b-form-input class="email" v-model="change.email"></b-form-input>
        </b-form-group>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="changeEmail()" :disabled="this.change.loading">
            Alterar
            <b-spinner v-if="change.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="change.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
.code {
  font-size: 1.3rem;
  letter-spacing: 5px;
  text-align: center;
}
</style>